import React from "react";
import resumeFile from "../documents/Taiwo Coker - CV.pdf";



const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [

    {
      yearRange: "2023 - 2025",
      title: "MBA - Master's of Businness Administration. ( In-View)",
      place: "University of East London",
      // desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    },

    {
      yearRange: "2023 - 2025",
      title: "Msc. CyberSecurity ( In-View)",
      place: "Stephen F. Austin state University",
      // desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    },
    {
      yearRange: "2015 - 2021",
      title: "Computer Science",
      place: "University of the People",
      desc: "Focusing on Data Mining & Data Analysis / Programing in C,C++, C# Languages. Good skills in SAS & R Language",
    },
   

    {
      yearRange: "2009 - 2013",
      title: "Electromechanical Engineering",
      place: "Institute for Industrial Technology",
      // desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    },
    
  ];

  const experienceDetails = [
    {
      yearRange: "2022 - Present",
      title: "Cloud Solutions Architect (AWS)",
      place: "TotalEnergies",
      desc: <h7>• Actively manage the day to day AWS accounts, recommending Optimize Pricing Models based on critical factors like Instances, Hosts, Clusters, Cache, Storage Type and Volumes, Elastic IP, Data Transfer, Application Load Balancing, Network Load Balancing, DNS Configuration, Email, Notification Services, Directory Services, Queue Services, Virtual Private Cloud. <br></br> 
      • Design, develop, and deploy Proofs of Concept (POCs) with prospective customers and partners.</h7>,
    
    },
    {
      yearRange: "2021 - 2022",
      title: "Cloud Engineer",
      place: "TotalEnergies",
      desc: <h7>• Built VPCs from scratch, creating private and public sub-nets, creating security groups and network access lists, configuring internet gateways, creating AMI, understanding of user access management/role based access/multi factor authentication and API access, configuration of auto scaling and elastic load balancer for scaling services if a configured threshold has been exceeded, configuration of SNS to send notifications and Cloud Watch to collect logs and metrics, spinning both Windows and Linux EC2 instances as needed. <br></br> 
      • Created Snapshots and AMIs of EC2 Instances and use them as backup for future DR. <br></br> 
      • Developed Security Architecture for highly scalable and fault-tolerant applications that adhere to expected standards and discipline from a security posture.</h7>,
    },
    // {
    //   yearRange: "2016 - 2021",
    //   title: "Aviation Maintenance Technician",
    //   place: "TotalEnergies",
    //   desc:<h7>•  Maintain JUHI Depot of 16,000m3 storage of Jet A1 <br></br>
    //   • Maintain pump electrical motors, panels and electrical panels in line with the standards<br></br>
    //   • Maintain the 6 Km hydrant network with 48 Hydrant Pits.<br></br>
    //   • Ensure good Audits recommendations and close outs.</h7>
    // },

    {
      yearRange: "2014 - 2016",
      title: "Maintenance Technician",
      place: "Guinness Nigeria Plc Ogba Lagos",
    //   desc: <h7>• Maintain a safe and healthy working environment. <br></br>
    //   • Comply with VPO safety, health and environmental policies, procedures, and legislation. <br></br>
    //   • Maintain safety and housekeeping standards.<br></br>
    //   • Maintain and repair plant and associated devices.<br></br>
    //   • Locate plant, equipment, spares, and relevant documentation and or systems.</h7>
    },
  ];
    
  const skills = [

    {
      name: "Cybersecurity",
      percent: 100,
    },
    {
      name: "AWS Solutions Architect",
      percent: 100,
    },
    {
      name: "DevOps",
      percent: 100,
    },
    {
      name: "Kubernetes",
      percent: 90,
    },
    {
      name: "Terraform",
      percent: 100,
    },
    {
      name: "Docker",
      percent: 99,
    },
    {
      name: "Linux/MacOs/Windows",
      percent: 99,
    },
    {
      name: "HTML/CSS",
      percent: 65,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

  
      <div className="container">
      <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            
        <h2 className="text-4 mb-4">{/* Your title */}</h2>
        <div className="education-container">
          {educationDetails.length > 0 &&
            educationDetails.map((value, index) => (
              <div
                key={index}
                className={
                  "education-card bg-white rounded p-4 mb-4 " +
                  (darkTheme ? "bg-dark" : "bg-white border")
                }
              >
                <p className="badge bg-primary text-2 fw-400">
                  {value.yearRange}
                </p>
                <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                  {value.title}
                </h3>
                <p className={darkTheme ? "text-primary" : "text-danger"}>
                  {value.place}
                </p>
                <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                  {value.desc}
                </p>
              </div>
            ))}
        </div>



          {/* My Experience */}
          <div className="row gx-5">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>

        

        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );



};
export default Resume;
