import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";

const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    DESIGN: "Projects",
    BADGES: "Badges",
    //BRAND: "Brand",
    // PHOTOS: "Photos",
    
  };

  const projectsData = [ 
     {
       title: "TIM APP",
       projectInfo:
       <p className={darkTheme ? "text-white-50" : ""}>
          We needed to reduce our paper usage and also enhance our reporting system from our Oil & Gas engineers on field/ Safety Personals on site.  Also we wanted a clear and transparent reporting system between our contractors , Engineers and Managers. So I and My team came up with this idea.
          We created an App that allows each site create their own Forms to substitute the paper type. The App's Security should be top notch.
          For the security aspect, I used the least privilege principle and also made 2 factor Authentication compulsory.
          User login with their Staff IGG via and gets a security code via the Memority App on their Devices.
          I use Terraform for provisioning infrastructures into AWS and use Ansible as my configuration tool.
       </p>,
      name: "Tim",
      client: "TotalEnergies",
      technologies: "Android, HTML5, Azure, PSQL, Terraform, Ansible, Git",
      industry: "Oil & Gas",
      date: "January 16, 2021",
      url: {
        name: "TIM APP",
        link: "https://mytim.azr.tgscloud.net/",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:taiwocoker@yahoo.com",
      },
      thumbImage: "images/projects/project-1.jpg",
      sliderImages: [
        "images/projects/project-1.2.jpg",
        "images/projects/project-1.1.jpg",
      ],
      categories: [filters.DESIGN],
    },
    {
      title: "Cloud Migration Project",
      projectInfo:
      <p className={darkTheme ? "text-white-50" : ""}>
          <br></br>•	Designed and implemented a cloud migration solution for a large retail company, moving their on-premises infrastructure to the AWS cloud
          <br></br>•	Leveraged AWS services, including EC2, S3, and RDS, to build a scalable and reliable cloud infrastructure
          <br></br>•	Developed infrastructure as code using Terraform to automate infrastructure deployment and reduce manual effort
          <br></br>•	Designed and implemented CI/CD pipelines using Jenkins and GitLab to enable frequent and reliable deployments
          <br></br>•	Collaborated with cross-functional teams to ensure successful project delivery

      </p>,
      
      //client: "",
      technologies: "AWS, CI/CD, GitLab, RDS, S3",
      industry: "Oil & Gas",
      date: "July 16, 2018",
      // url: {
      //   name: "Confidential",
      //   link: "https://www.example.com",
      // },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:taiwocoker@yahoo.com",
      },
      thumbImage: "images/projects/project-2.jpg",
      sliderImages: [
        "images/projects/project-2.1.jpg",
        "images/projects/project-2.2.jpg",
      ],
      categories: [filters.DESIGN],
    },
    {
      title: "Kubernetes Deployment Project",
      projectInfo:
          <p className={darkTheme ? "text-white-50" : ""}>
          <br></br>•	Designed and implemented a Kubernetes deployment solution for a financial services company, enabling them to deploy and scale their applications more efficiently
          <br></br>•	Leveraged Docker and Kubernetes to containerize applications and orchestrate deployments
          <br></br>•	Developed infrastructure as code using Terraform to automate infrastructure deployment and reduce manual effort
          <br></br>•	Designed and implemented CI/CD pipelines using Jenkins and GitLab to enable frequent and reliable deployments
          <br></br>•	Collaborated with cross-functional teams to ensure successful project delivery

          </p>,
     // client: "TotalEnergies",
      technologies: "CI/CD, Terraform, Kubernetes, GitLab, Docker",
      industry: "Oil & Gas",
      date: "November, 2022",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:taiwocoker@yahoo.com",
      },
      thumbImage: "images/projects/project-3.jpg",
      sliderImages: [
        "images/projects/project-3.1.jpg",
        "images/projects/project-3.2.jpg",
      ],
      categories: [filters.DESIGN],
    },
    {
      title: "Automated Testing Project",
      projectInfo:
          <p className={darkTheme ? "text-white-50" : ""}>
          <br></br>•	Developed an automated testing framework using Python and Selenium for a healthcare company, improving product quality and reducing manual testing effort
          <br></br>•	Implemented automated testing for both front-end and back-end components, including UI testing, API testing, and integration testing
          <br></br>•	Designed and implemented CI/CD pipelines using Jenkins and GitLab to automate the testing process and ensure reliable deployments
          <br></br>•	Collaborated with development teams to identify and fix defects early in the development cycle

          </p>,
      //   client: "Ruby Clinton",
      technologies: "CI/CD, GitLab, Python, Selenium, Jenkins",
      industry: "Art & Design",
      date: "April, 2021",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:taiwocoker@yahoo.com",
      },
      thumbImage: "images/projects/project-4.jpg",
      sliderImages: [
        "images/projects/project-4.1.jpg",
        "images/projects/project-4.jpg",
      ],
      categories: [filters.DESIGN, filters.PHOTOS],
    },
    {
      title: "AWS CLOUD PRACTITIONER",
      name: "Taiwo Coker",
      technologies: "AWS",
      industry: "Cloud Services",
      date: "FEBUARY, 2022",
      url: {
        name: "Validate",
        link: "https://www.credly.com/badges/a4a69bed-7fa2-4643-b074-30e956ccc100?source=linked_in_profile",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:taiwocoker@yahoo.com",
      },
      thumbImage: "images/projects/AWS-Certified-Cloud-Practitioner_badge.634f8a21af2e0e956ed8905a72366146ba22b74c.png",
      sliderImages: [
        "images/projects/aws-cloud-practitioner.jpg",
        "images/projects/images/projects/AWS-Certified-Cloud-Practitioner_badge.634f8a21af2e0e956ed8905a72366146ba22b74c.png",
      ],
      categories: [filters.BADGES],
    },


    {
      title: "AWS SOLUTIONS ARCHITECT",
      name: "Taiwo Coker",
      technologies: "AWS",
      industry: "Cloud Services",
      date: "MAY, 2022",
      url: {
        name: "Validate",
        link: "https://www.credly.com/badges/1c1e07ab-b636-405b-9a5f-8c751aba4056?source=linked_in_profile",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:taiwocoker@yahoo.com",
      },
      thumbImage: "images/projects/AWS-Certified-Solutions-Architect-Associate_badge.3419559c682629072f1eb968d59dea0741772c0f.jpg",
      sliderImages: [
        "images/projects/aws-solutions-architect.jpg",
        "images/projects/AWS-Certified-Solutions-Architect-Associate_badge.3419559c682629072f1eb968d59dea0741772c0f.jpg",
      ],
      categories: [filters.BADGES],
    },


    {
      title: "Terraform Associate Certification: HashiCorp Certified",
      name: "Taiwo Coker",
      technologies: "TERRAFORM",
      industry: "Cloud Services",
      date: "OCTOBER, 2022",
      url: {
        name: "Validate",
        link: "https://kodekloud.com/certificate-verification/7C87D08855-83958A26C5-7A692C5DAD/",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/terraform.png",
      sliderImages: [
        "images/projects/kodekloud1.jpg",
        "images/projects/terraform.png",
        
      ],
      categories: [filters.BADGES],
    },


    // {
    //   title: "Project Title 7",
    //   projectInfo:
    //     "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    //   client: "Ruby Clinton",
    //   technologies: "iOS, HTML5, CSS3, PHP, Java",
    //   industry: "Art & Design",
    //   date: "July 16, 2019",
    //   url: {
    //     name: "www.example.com",
    //     link: "https://www.example.com",
    //   },
    //   socialLinks: {
    //     facebook: "http://www.facebook.com/",
    //     twitter: "http://www.twitter.com/",
    //     google: "http://www.google.com/",
    //     instagram: "http://www.instagram.com/",
    //     mail: "mailto:example@gmail.com",
    //   },
    //   thumbImage: "images/projects/project-7.jpg",
    //   sliderImages: [
    //     "images/projects/project-1.jpg",
    //     "images/projects/project-5.jpg",
    //   ],
    //   categories: [filters.DESIGN, filters.PHOTOS],
    // },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
