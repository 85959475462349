import React from "react";
import resumeFile from "../documents/Taiwo Coker - CV.pdf";
const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About Me
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Know Me More
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              I'm <span className="text-primary">Taiwo Coker,</span> a Cybersecurity Analyst/Cloud Solutions Architect
            </h2>
            <p className={darkTheme ? "text-white-50" : ""}>
            I am a Seasoned Professional and life-long learner. I dive deep into understanding how things work and keep myself updated with latest technologies. <br></br> 
            As a problem solver, I create innovative yet simple solutions to complex problems and support teams/clients through their cloud journey. 
            The Cloud afforded me the chance to use my 9 years of experience in the Production/Aviation and 4 years in AWS multiple cloud services. With this Opportunity, I do things I love while I keep learning new services and efficient ways to create top notch solutions for my clients.

            <br></br>Over the years, I have perfected my skills in migration, virtualization, storage, provisioning, Application and software management to mention a few, using AWS services like EC2, S3, VPC, Auto scaling, Cloud front, Cloud Watch, CI CD Automation tools and Configuration Management tools.

            I am at the moment pursuing a Master's in Cybersecurity while constantly seeking new challenges to better my knowledge.<br></br>
            My MOTTO is "Delivering work within a time frame and budget that satisfy the  client’s requirements".
            </p>
            
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>Taiwo Coker
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:taiwo.coker@yahoo.com">taiwo.coker@yahoo.com</a>
                </li>
                {/* <li> */}
                  {/* <span className="fw-600 me-2">Age:</span>31 */}
                {/* </li> */}
                <li className="border-0">
                  <span className="fw-600 me-2">From:</span>Lagos,
                  Nigeria
                </li>
              </ul>
              <a
                href={resumeFile}
                download
                className="btn btn-primary rounded-pill"
              >
                Download CV
              </a>
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>9</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years Experience
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>50</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Happy Clients
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>150</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Projects Done
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>5</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Awards
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
