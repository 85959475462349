import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // services details
  const services = [


    {
      name: "CyberSecurity Analyst",
      desc: <p className={darkTheme ? "text-white-50" : ""}>
      <br></br>As a Cloud Security Analyst, I am responsible for ensuring the security and compliance of cloud-based systems and applications. I work closely with development, operations, and security teams to identify and assess security risks, design and implement security controls, and monitor and respond to security incidents.
      <br></br>I am responsible for developing and implementing security policies, procedures, and standards that align with industry best practices and regulatory requirements. I perform vulnerability assessments and penetration testing to identify potential vulnerabilities and ensure that appropriate measures are in place to mitigate them.
      <br></br>I collaborate with development teams to ensure that security is integrated into the software development lifecycle, including the design, development, testing, and deployment phases. I also work with operations teams to ensure that systems and applications are configured and managed in a secure manner.
      <br></br>I monitor cloud-based systems and applications for security incidents, and respond to incidents in a timely and effective manner. I also provide guidance and support to internal stakeholders and customers on security-related issues.
      <br></br>Ultimately, my goal is to ensure the security and compliance of cloud-based systems and applications, while enabling business agility and innovation. I work to ensure that security is integrated into all aspects of cloud-based systems and applications, and that security risks are identified and mitigated in a proactive manner.
      </p>,
      icon: "fas fa-satellite",
    },
    
    {
      name: "Cloud Solutions Architecture",
      desc: <p className={darkTheme ? "text-white-50" : ""}>
      <br></br>As a Cloud Solutions Architect, I collaborate with customers, account managers, business development, engineering, and product teams to develop scalable, flexible, and resilient cloud-based solutions that address customer business problems and accelerate the adoption of cloud services.
      <br></br>I work closely with stakeholders to understand their requirements and design solutions that are optimized for performance, cost, and security. I am responsible for selecting and integrating cloud services and technologies that meet customer needs, while also ensuring that solutions are well-architected, compliant with industry standards, and able to scale to meet future needs.
      <br></br>I also provide guidance on cloud migration strategies, helping customers to evaluate their existing infrastructure and applications and determine the best approach for moving to the cloud. Throughout the process, I work to ensure that the solution is designed with best practices for security, reliability, and performance in mind.
      <br></br>In addition, I provide ongoing support to customers, assisting with troubleshooting, optimization, and scaling as needed. Ultimately, my goal is to enable customers to leverage the full potential of cloud technology to achieve their business goals, while minimizing risk and maximizing return on investment.
      </p>,
      icon: "fas fa-server",
    },
    /*{
      name: "DevOps",
      desc: <p className={darkTheme ? "text-white-50" : ""}>
      <br></br>As a DevOps Engineer, I work closely with development, operations, and other cross-functional teams to design and implement automated, streamlined, and reliable software development and deployment processes. I collaborate with stakeholders to understand business requirements and develop solutions that optimize software delivery and performance.
      <br></br>I help to implement Continuous Integration/Continuous Deployment (CI/CD) pipelines that enable fast and frequent releases of software, while ensuring quality and reliability. I also focus on monitoring, testing, and troubleshooting, and use feedback to continually improve software and infrastructure performance.
      <br></br>I am responsible for selecting and integrating tools and technologies that support efficient software development and deployment, and I work to ensure that our teams have the necessary skills and knowledge to use them effectively. 
      <br></br>Ultimately, my goal is to improve software delivery speed, quality, and reliability while reducing risk and cost, and to drive collaboration and communication across teams.
      </p>,
      icon: "fas fa-pencil-ruler",
    },*/

    // {
    //   name: "Web Design",
    //   desc: "As a web designer, I plan, create and code internet sites and web pages, many of which combine text with sounds, pictures, graphics and video clips. I am responsible for creating the design and layout of a website or web pages. It and can mean working on a brand new website or updating an already existing site.",
    //   icon: "fas fa-desktop",
    // },
   
    // {
    //   name: "App Design & Develop",
    //   desc: "In-View",
    //   icon: "fas fa-paint-brush",
    // },
    // {
    //   name: "DevOps",
    //   desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    //   icon: "fas fa-chart-area",
    // },
    
  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Services
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            What I Do?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {service.desc}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
