import React from "react";

import Slider from "react-slick";
// import { IoLocationSharp } from "react-icons/io5";
import { FaLocationArrow } from "react-icons/fa";




function Testimonials({ classicHeader, darkTheme }) {
  const reviews = [
    {
      name: "OpeOluwa Adelaja",
      position: <h7> Product and Implementation Manager. <br></br><FaLocationArrow size={15} style={{ fill: 'red' }} /> Texas, USA</h7>,
      src: "images/testimonial/opeoluwa2.jpeg",
      desc: "We are at forefront of cloud technologies having hardly any on-prem infrastructure. With diverse workloads like ERP, advanced analytics and IOT at various stages of deployment, we were looking for a partner with depth and breadth of expertise that can cover all our needs. With Taiwo Coker we have a partner who has both strategic vision coupled with delivery capabilities for timely cost effective implementation. We believe that together with him, we will keep on achieving new heights.",
      rating: 5,
      
    },
    {
      name: "Jerimiah Ajiboye",
      position: <h7>Senior CyberSecurity Analyst. <br></br>  <FaLocationArrow size={15} style={{ fill: 'red' }} /> Caymen Island</h7>,
      src: "images/testimonial/Jerimiah Ajiboye.jpeg",
      desc: "With Taiwo Coker, we have found a partner with excellent cloud experience. This was evident in the successful orchestration of our Cloud migration. After a few other projects, He is now our go-to-partner for all our AWS needs.",
      rating: 5,
    },
    {
      name: "Alex Kehinde Bankole",
      position: <h7> CEO, KABS Construction Limited. <br></br> <FaLocationArrow size={15} style={{ fill: 'red' }} /> Lagos, Nigeria</h7>,
      src: "images/testimonial/Alex.jpeg",
      desc: "As a small business, we like many, do not have access to the resources and expertise to modernize our IT infrastructure.  The goal in working with Taiwo Coker was to ensure that our most critical data assets  and communications were integrated, secure and accessible. Taiwo Coker designed and implemented an Office 365 integration solution that created critical backup files from individual machines while providing a secure framework using the mobile office to view , edit and share files on the go.",
      rating: 5,
    },
    {
      name: "Oluwatosin",
      position: <h7>AWS Solutions Architect.<br></br><FaLocationArrow size={15} style={{ fill: 'red' }} /> Liverpool, UK</h7>,
      src: "images/testimonial/Oluwatosin.jpeg",
      desc: "I have used him twice now for my AWS Solutions. Good rates, and very efficient service, he is Excellent.",
      rating: 5,
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section
      id="testimonial"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={"text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")}
          >
            Testimonial
          </h2>
          <p
            className={"text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")}
          >
            {" "}
            Client Speak
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((value, index) => (
              <div className="item" key={index}>
                <div
                  className={" rounded p-5 " + (darkTheme ? "bg-dark" : "bg-light")}
                >
                  <div className="d-flex align-items-center mt-auto mb-4">
                    <img
                      className="img-fluid rounded-circle border d-inline-block w-auto"
                      src={value.src}
                      alt="" />
                    <p className="ms-3 mb-0">
                      <strong
                        className={"d-block fw-600 " +
                          (darkTheme ? "text-white" : "text-dark")}
                      >
                        {value.name}
                      </strong>
                      <span className="text-muted fw-500">
                        {" "}
                        {value.position}{" "}
                      </span>
                    </p>
                  </div>
                  <p
                    className={" fw-500 mb-4 " + (darkTheme ? "text-white" : "text-dark")}
                  >
                    “{value.desc}”
                  </p>
                  <span className="text-2">
                    {[...Array(value.rating)].map((value, i) => (
                      <i className="fas fa-star text-warning" key={i} />
                    ))}
                  </span>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
}

export default Testimonials;
